<template lang="pug">
.full-box.page-main(v-loading="loading")
  .page-left-box
    label.blue-header 费用结算信息
    ul.bill-total-info
      li(
        v-for="field in totalFields"
        :key="field.name")
        span.total-label {{ field.label }}:
        span.total-content {{ totalFormData[field.name] | renderTotal(field) }}
    ButtonGroup(
      :buttonList="buttonList")
  .bgc-block
  .page-right-box
    .table-box
      label.blue-header 用水户（先搜索用水户再选择账单）
      DataTable.page-table(
        ref="usewaterTable"
        keyName="usewaterTable"
        defaultEmpty
        serverSide
        highlightClickRow
        :needFilterMargin="false"
        resource="/usewater/list"
        size="medium"
        :filterFields="usewaterFields"
        :columns="usewaterColumns"
        @current-change="handleUsewaterChange")
    .bgc-block-cross
    .table-box
      label.blue-header 账单信息
      .page-table
        DataTable(
          v-if="!!billExtraFilterBy.usewaterId && showBillTable"
          ref="billTable"
          keyName="billTable"
          serverSide
          isSelection
          :needFilterMargin="false"
          :showFilter="false"
          resource="/bill/list"
          size="medium"
          :defaultSortBy="[{prop: 'billCreateTime', order: 'desc'}]"
          :filterFields="filterFields"
          :columns="billColumns"
          :extraFilterBy="billExtraFilterBy"
          :selectable="handleSelectable"
          :operationWidth="80"
          :operateButtonList="billExtraFilterBy.billPayStatus === 'UNPAY' ? [] : operateButtonList"
          :cellClassNameFunc="billPayStatusClassFunc"
          @selectionChange="handleSelectionChange")
          DataForm.datatablepage-filter(
            slot="filterInfo"
            v-model="billExtraFilterBy"
            ref="filterForm"
            type="inline"
            :formFields="[filterFields[0]]"
            @onChange="handleBillFilterChange")
        .empty(v-else) 请先选择用水户
  </template>

<script>
import { mapActions } from 'vuex'
import { billPayStatusClassFunc } from '@/utils/common.js'
import round from 'lodash/round'
import add from 'lodash/add'

export default {
  name: 'BillPay',
  filters: {
    renderTotal (val, field) {
      const value = val || 0
      const str = field.toFixed === false ? value : value.toFixed(2)
      const unit = field.unit || '元'
      return str + unit
    }
  },
  computed: {
    sumFields () {
      const fields = [{ name: 'quantity', label: '水量' }]
      return this.totalFields.concat(fields)
    },
    totalFormData () {
      const initValue = {
        billNum: this.selections.length,
        waterAmount: 0,
        sewageAmount: 0,
        waterResourceAmount: 0,
        penaltyAmount: 0,
        totalAmount: 0
      }
      return this.selections.reduce((result, select) => {
        this.sumFields.forEach(field => {
          if (field.toFixed !== false) {
            result[field.name] = round(add(result[field.name], Number(select[field.name])), 2)
          }
        })
        return result
      }, initValue)
    }
  },
  data () {
    return {
      billPayStatusClassFunc,
      totalFields: [
        { name: 'billNum', label: '账单笔数', toFixed: false, unit: '笔' },
        { name: 'waterAmount', label: '水费合计' },
        { name: 'sewageAmount', label: '污水费合计' },
        { name: 'waterResourceAmount', label: '水资源费合计' },
        { name: 'penaltyAmount', label: '滞纳金合计' },
        { name: 'totalAmount', label: '总金额合计' }
      ],
      buttonList: [{
        name: 'pay',
        label: '缴费',
        type: 'success',
        func: this.showConfirm
      }],
      currentUsewater: {},
      usewaterColumns: [
        { name: 'usewaterCode', label: '用水户号', width: 120 },
        {
          name: 'customerName',
          label: '用户',
          minWidth: 160,
          render: (value, field, data) => {
            const { customerName, customerCode } = data
            return `${customerName}(${customerCode})`
          }
        },
        { name: 'usewaterAddress', label: '用水地址', minWidth: 200 }
      ],
      usewaterFields: [{
        name: 'keyword',
        label: '关键词',
        form: {
          tag: 'input',
          like: true,
          placeholder: '用水户号/用水户名/用户编号/用户姓名',
          inputWidth: '290px'
        }
      }],
      showBillTable: false,
      // 表格筛选表单配置
      filterFields: [{
        name: 'billPayStatus',
        label: '缴费状态',
        form: {
          tag: 'select',
          relation: 'billPayStatus'
        }
      }, {
        name: 'usewaterId',
        label: '用水户',
        showable: false,
        form: {}
      }],
      billColumns: [
        { name: 'billPayStatus', label: '缴费状态', relation: 'billPayStatus', render: { type: 'select' } },
        { name: 'billMonth', label: '账期', minWidth: 80 },
        { name: 'billNo', label: '账单编号', minWidth: 130 },
        { name: 'quantity', label: '水量' },
        { name: 'totalAmount', label: '总金额', minWidth: 100 },
        { name: 'waterAmount', label: '水费', minWidth: 100 },
        { name: 'sewageAmount', label: '污水费', minWidth: 100 },
        { name: 'waterResourceAmount', label: '水资源费', minWidth: 100 },
        { name: 'penaltyAmount', label: '滞纳金', minWidth: 100 },
        { name: 'penaltyDays', label: '滞纳天数' }
      ],
      operateButtonList: [{
        label: '冲销',
        name: 'writeOff',
        type: 'text',
        textType: 'danger',
        disabled: (row) => {
          return row.billPayStatus === 'UNPAY'
        },
        func: this.handleWriteOff
      }],
      billExtraFilterBy: {
        billPayStatus: 'UNPAY',
        usewaterId: null
      },
      selections: []
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    handleUsewaterChange (row) {
      this.currentUsewater = row
      this.$set(this.billExtraFilterBy, 'billPayStatus', 'UNPAY')
      this.$set(this.billExtraFilterBy, 'usewaterId', row ? row.id : null)
      if (this.$refs.billTable) {
        this.$refs.billTable.clearSelection()
        this.$refs.billTable.reRenderTableData()
      }
    },
    handleSelectable (row) {
      return row.billPayStatus === 'UNPAY'
    },
    handleSelectionChange (rows) {
      this.selections = rows
    },
    renderRelation () {
      this.getRelations(['billPayStatus'])
        .then(res => {
          this.$renderRelationColumns(res, [this.billColumns, this.filterFields])
          this.$nextTick(() => { this.showBillTable = true })
        })
    },
    renderConfirmContent () {
      const h = this.$createElement
      const { usewaterCode, customerName, customerCode, usewaterAddress } = this.currentUsewater
      const data = {
        usewaterCode,
        customerName,
        customerCode,
        usewaterAddress,
        ...this.totalFormData
      }
      const infoList = [
        { name: ['usewaterCode'], label: '用水户号' },
        { name: ['customerName', 'customerCode'], label: '用户' },
        { name: ['usewaterAddress'], label: '用水地址' },
        { name: ['quantity'], label: '水量', unit: 'm³' },
        { name: ['totalAmount'], label: '总金额', unit: '元' }
      ]
      const list = infoList.map(field => {
        const [key1, key2] = field.name
        const content = key2 ? `${data[key1]}(${data[key2]})` : data[key1]
        return h('li', {}, [
          h('span', { class: 'total-label' }, `${field.label}:`),
          h('span', { class: 'total-content' }, content + (field.unit || ''))
        ])
      })
      return h(
        'div',
        {},
        [
          h('ul', { class: 'bill-total-confirm' }, list)
        ]
      )
    },
    showConfirm () {
      if (!this.selections.length) {
        const msg = '请先选择需要缴费的账单'
        this.$message.warning(msg)
        return
      }
      const message = this.renderConfirmContent()
      this.$msgbox({
        title: '确认收费',
        message,
        showCancelButton: true,
        confirmButtonText: '收费',
        cancelButtonText: '取消',
        beforeClose: async (action, instance, done) => {
          try {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              await this.handlePay()
              instance.confirmButtonLoading = false
            }
            done()
          } catch (error) {
            done()
          }
        }
      })
        .catch(console.error)
    },
    handlePay () {
      return new Promise((resolve, reject) => {
        const ids = this.selections.map(item => item.id)
        this.$post({
          url: '/bill/manualPay',
          data: {
            ids
          }
        })
          .then(res => {
            if (!res) {
              reject(res)
            } else {
              this.$message.success('缴费成功')
              this.$refs.billTable.clearSelection()
              this.$refs.billTable.reRenderTableData()
              // this.$refs.usewaterTable.setCurrentRow()
              resolve('success')
            }
          })
          .catch(reject)
      })
    },
    handleWriteOff ({ data }) {
      return new Promise((resolve, reject) => {
        this.$confirm('是否确认冲销', '提示')
          .then(() => {
            this.$patch({
              url: `/bill/writeOff/${data.id}`
            })
              .then(res => {
                if (!res) {
                  reject(res)
                } else {
                  this.$message.success('冲销成功')
                  this.$refs.billTable.reRenderTableData()
                  resolve('success')
                }
              })
              .catch(reject)
          })
          .catch(reject)
      })
    },
    handleBillFilterChange () {
      this.$refs.billTable.clearSelection()
      this.$refs.billTable.reRenderTableData()
    }
  },
  created () {
    this.renderRelation()
  },
  mounted () {
  }
}
</script>

<style lang="sass" scoped>
.page-main
  display: flex
  flex-direction: row
  .page-left-box
    width: 260px
    overflow-y: auto
    padding: 8px
    .bill-total-info
      margin-bottom: 8px
      li
        line-height: 36px
        .total-label
          color: #595959
        .total-content
          margin-left: 8px
          font-weight: 600
  .page-right-box
    flex: 1
    background-color: #fff
    overflow-y: auto
    .table-box
      height: calc( 50% - 4px )
      padding: 8px
      .page-table
        width: 100%
        height: calc( 100% - 30px )
  .bgc-block-cross
    width: 100%
    height: 8px
    background-color: #eceff0
  .bgc-block
    width: 8px
    height: 100%
    background-color: #eceff0
  .blue-header
    margin-bottom: 8px
.bill-total-confirm
  margin-bottom: 8px
  li
    line-height: 36px
    .total-label
      color: #595959
    .total-content
      margin-left: 8px
      font-weight: 600
</style>
